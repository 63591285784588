const constants = {
  palette: {
    light: "#f7f4ea",
    medium1: "#ded9e2",
    medium2: "#80a1d4",
    medium3: "#75c9c8",
    dark: "#c0b9dd",
  },
};

export default constants;
